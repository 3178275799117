<template>
  <div class="detailBtnPage app-container">
    <el-button size="small" type="primary" @click="$router.back()">返回</el-button>
    <baseTable :resetMergeData="resetMergeData" :option="option" ref="table" :api="getList" checkAll>
      <template #menuLeft="scoped">
        <el-button style="margin-bottom: 20px" size="small" type="primary" :loading="exportLoading" @click="exportBill"
          >账单导出</el-button
        >
      </template>

      <template #menu="{ row }">
        <ColorTextBtn @click="detail(row)">查看详情</ColorTextBtn>
      </template>
    </baseTable>
  </div>
</template>

<script>
import formPart from '../../formPart.vue'
import { formColData3 as formColData } from '../../formPartFile'
import { tableCols, option } from './detailTableCols'
import { paymentOrderList as getList, reckoningExportOld } from '@/api/financial/detailbtn'
import saveAs from 'jszip/vendor/FileSaver.js'

export default {
  components: {
    formPart
  },
  props: {},
  data() {
    return {
      option,
      sup_this: this,
      formColData,
      tableCols,
      resetMergeData: {
        paymentProductId: this.$route.query.paymentOrderId
      },
      billList: [],
      exportLoading: false
    }
  },
  computed: {
    isPaymentOrderCode() {
      console.log(this.$route.query.paymentOrderCode)
      return this.$route.query.paymentOrderCode
    },
    isStatus() {
      return (val) => {
        switch (val) {
          case 1:
            return '正常'
          case 0:
            return '异常'
          default:
            return '暂无'
        }
      }
    }
  },
  methods: {
    getList,
    detail(row) {
      const { paymentAmountType } = row
      sessionStorage.setItem('paymentOrderData', JSON.stringify(row || {}))
      this.$router.push({
        path: '/financialManagement/moredetail',
        query: {
          orderId: row.id,
          paymentAmountType
        }
      })
    },
    async exportBill() {
      this.billList = await this.$refs.table.getSelectionDataAllArr()
      if (this.billList.length === 0) return this.$message.warning('请选择账单')
      this.exportLoading = true
      const idList = this.billList.map(({ id }) => id)
      const paymentAmountType = $GET(this.billList, '0.paymentAmountType')
      reckoningExportOld({ idList, paymentAmountType })
        .then((res) => {
          // const fileName = type == 2 ? '按款式导出的发票详情数据' : '按订单导出的发票详情数据'
          const fileName = '订单审核详情'
          saveAs(res, fileName)
          this.$message.success('导出成功')
        })
        .catch((e) => {
          console.log(e)
          this.$message.error('导出失败')
        })
        .finally(() => {
          this.exportLoading = false
        })
      // console.log('this.reviewdata',this.reviewData.id);
    }
  }
}
</script>

<style scoped lang="scss">
.detailBtnPage {
  ::v-deep {
    .el-table__body .el-table__row td {
      padding: 0;
      .cell {
        // padding: 0;
        .priceClass {
          line-height: 45px;
          border-bottom: 1px solid #ebeef5;
          &:last-child {
            border-bottom: none;
          }
        }
        .productInfoClass {
          line-height: 30px;
          border-bottom: 1px solid #ebeef5;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
